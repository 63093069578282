import React from "react";

export const GrowIcon: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_403_3106)">
      <path
        d="M22.4998 3.97607H19.4138C19.2161 3.97612 19.0228 4.03479 18.8584 4.14467C18.694 4.25456 18.5658 4.41072 18.4902 4.59342C18.4145 4.77612 18.3947 4.97715 18.4333 5.17111C18.4718 5.36506 18.567 5.54322 18.7068 5.68307L19.7928 6.76907L16.2068 10.3541C16.0163 10.5362 15.7629 10.6378 15.4993 10.6378C15.2358 10.6378 14.9824 10.5362 14.7918 10.3541L14.6218 10.1831C14.0505 9.63715 13.2906 9.3325 12.5003 9.3325C11.7101 9.3325 10.9502 9.63715 10.3788 10.1831L5.27885 15.2831C5.09094 15.4715 4.98559 15.7269 4.98596 15.993C4.98634 16.2591 5.09241 16.5142 5.28085 16.7021C5.46928 16.89 5.72465 16.9953 5.99076 16.995C6.25688 16.9946 6.51194 16.8885 6.69985 16.7001L11.7998 11.6001C11.9903 11.4178 12.2437 11.316 12.5073 11.316C12.771 11.316 13.0244 11.4178 13.2148 11.6001L13.3848 11.7711C13.9565 12.3165 14.7162 12.6209 15.5063 12.6209C16.2965 12.6209 17.0562 12.3165 17.6278 11.7711L21.2138 8.18508L22.2998 9.27107C22.4402 9.40862 22.6179 9.50178 22.8109 9.53892C23.0038 9.57606 23.2034 9.55553 23.3848 9.47989C23.5661 9.40426 23.7212 9.27687 23.8306 9.11364C23.94 8.95041 23.9988 8.75857 23.9998 8.56208V5.47607C23.9998 5.07825 23.8418 4.69672 23.5605 4.41541C23.2792 4.13411 22.8977 3.97607 22.4998 3.97607Z"
        fill="blue"
      />
      <path
        d="M23 21.976H5C4.20435 21.976 3.44129 21.6599 2.87868 21.0973C2.31607 20.5347 2 19.7716 2 18.976V1C2 0.734784 1.89464 0.48043 1.70711 0.292893C1.51957 0.105357 1.26522 0 1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1L0 18.976C0.00158786 20.3016 0.528882 21.5724 1.46622 22.5098C2.40356 23.4471 3.67441 23.9744 5 23.976H23C23.2652 23.976 23.5196 23.8706 23.7071 23.6831C23.8946 23.4956 24 23.2412 24 22.976C24 22.7108 23.8946 22.4564 23.7071 22.2689C23.5196 22.0814 23.2652 21.976 23 21.976Z"
        fill="blue"
      />
    </g>
    <defs>
      <clipPath id="clip0_403_3106">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const ShieldCheck: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_403_3275)">
      <path
        d="M18.5811 2.14067L12.3161 0.0516681C12.111 -0.016653 11.8893 -0.016653 11.6841 0.0516681L5.41912 2.14067C4.42303 2.47155 3.55654 3.10793 2.94277 3.95938C2.329 4.81083 1.99916 5.83406 2.00012 6.88367V12.0007C2.00012 19.5637 11.2001 23.7407 11.5941 23.9147C11.7219 23.9715 11.8603 24.0008 12.0001 24.0008C12.14 24.0008 12.2783 23.9715 12.4061 23.9147C12.8001 23.7407 22.0001 19.5637 22.0001 12.0007V6.88367C22.0011 5.83406 21.6713 4.81083 21.0575 3.95938C20.4437 3.10793 19.5772 2.47155 18.5811 2.14067ZM20.0001 12.0007C20.0001 17.4557 13.6811 21.0337 12.0001 21.8897C10.3171 21.0367 4.00012 17.4697 4.00012 12.0007V6.88367C4.00018 6.25395 4.1984 5.64021 4.56669 5.12941C4.93498 4.61861 5.45467 4.23666 6.05212 4.03767L12.0001 2.05467L17.9481 4.03767C18.5456 4.23666 19.0653 4.61861 19.4336 5.12941C19.8018 5.64021 20.0001 6.25395 20.0001 6.88367V12.0007Z"
        fill="blue"
      />
      <path
        d="M15.2999 8.30032L11.1119 12.5003L8.86792 10.1603C8.77786 10.0619 8.66901 9.98242 8.54778 9.92667C8.42654 9.87092 8.29538 9.84001 8.16201 9.83573C8.02864 9.83146 7.89577 9.85393 7.77122 9.9018C7.64667 9.94968 7.53296 10.022 7.43679 10.1145C7.34062 10.207 7.26393 10.3178 7.21125 10.4404C7.15856 10.563 7.13095 10.6949 7.13003 10.8283C7.12911 10.9618 7.1549 11.094 7.20589 11.2173C7.25688 11.3407 7.33203 11.4525 7.42692 11.5463L9.73291 13.9463C9.90489 14.1321 10.1128 14.281 10.3439 14.3842C10.5751 14.4873 10.8248 14.5425 11.0779 14.5463H11.1109C11.359 14.5472 11.6047 14.4987 11.8339 14.4038C12.0631 14.3088 12.2711 14.1693 12.4459 13.9933L16.7179 9.72132C16.8112 9.62822 16.8853 9.51764 16.9358 9.39592C16.9864 9.27419 17.0125 9.14369 17.0127 9.01188C17.0128 8.88007 16.987 8.74952 16.9367 8.62769C16.8863 8.50585 16.8125 8.39512 16.7194 8.30182C16.6263 8.20852 16.5157 8.13446 16.394 8.08389C16.2723 8.03332 16.1418 8.00722 16.01 8.00708C15.8782 8.00694 15.7476 8.03277 15.6258 8.08308C15.5039 8.13339 15.3932 8.20721 15.2999 8.30032Z"
        fill="blue"
      />
    </g>
    <defs>
      <clipPath id="clip0_403_3275">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const ScreenIcon: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_403_2884)">
      <path
        d="M19 3H5C3.67441 3.00159 2.40356 3.52888 1.46622 4.46622C0.528882 5.40356 0.00158786 6.67441 0 8L0 14C0.00158786 15.3256 0.528882 16.5964 1.46622 17.5338C2.40356 18.4711 3.67441 18.9984 5 19H11V20H8C7.73478 20 7.48043 20.1054 7.29289 20.2929C7.10536 20.4804 7 20.7348 7 21C7 21.2652 7.10536 21.5196 7.29289 21.7071C7.48043 21.8946 7.73478 22 8 22H16C16.2652 22 16.5196 21.8946 16.7071 21.7071C16.8946 21.5196 17 21.2652 17 21C17 20.7348 16.8946 20.4804 16.7071 20.2929C16.5196 20.1054 16.2652 20 16 20H13V19H19C20.3256 18.9984 21.5964 18.4711 22.5338 17.5338C23.4711 16.5964 23.9984 15.3256 24 14V8C23.9984 6.67441 23.4711 5.40356 22.5338 4.46622C21.5964 3.52888 20.3256 3.00159 19 3ZM22 14C22 14.7956 21.6839 15.5587 21.1213 16.1213C20.5587 16.6839 19.7956 17 19 17H5C4.20435 17 3.44129 16.6839 2.87868 16.1213C2.31607 15.5587 2 14.7956 2 14V8C2 7.20435 2.31607 6.44129 2.87868 5.87868C3.44129 5.31607 4.20435 5 5 5H19C19.7956 5 20.5587 5.31607 21.1213 5.87868C21.6839 6.44129 22 7.20435 22 8V14Z"
        fill="blue"
      />
    </g>
    <defs>
      <clipPath id="clip0_403_2884">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
